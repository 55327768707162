import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 900.000000 1080.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)">
          
          <path d="M3942 8346 c-87 -22 -226 -118 -291 -203 -71 -92 -102 -186 -100
-308 0 -62 -4 -102 -15 -127 -32 -75 -7 -223 55 -322 17 -27 38 -71 48 -96 26
-68 85 -153 142 -204 169 -152 390 -151 560 2 59 53 119 140 145 210 10 26 30
65 45 87 59 88 88 263 54 328 -11 22 -13 49 -8 117 12 178 -40 310 -144 364
-18 9 -36 23 -40 31 -18 32 -117 96 -180 115 -75 23 -189 25 -271 6z m109
-403 c93 -32 117 -37 232 -41 122 -4 128 -6 147 -29 26 -34 43 -99 36 -138 -5
-27 -3 -33 19 -43 22 -10 25 -18 25 -61 0 -57 -38 -172 -68 -204 -11 -12 -27
-45 -36 -73 -65 -199 -268 -325 -435 -269 -107 35 -211 143 -251 261 -11 32
-29 67 -40 79 -22 23 -54 108 -64 171 -8 48 6 90 32 98 13 5 23 23 32 64 16
71 42 129 76 166 63 67 144 73 295 19z"/>
<path d="M4780 7943 c-8 -3 -26 -15 -38 -27 -51 -46 -52 -53 -52 -398 0 -288
2 -327 18 -357 31 -62 61 -76 173 -79 l99 -4 0 -109 c0 -60 3 -109 8 -109 4 0
57 50 117 110 l110 110 213 0 c242 0 265 5 304 69 23 36 23 40 23 371 0 373 1
369 -68 410 -30 19 -53 20 -462 19 -236 0 -437 -3 -445 -6z m818 -420 l2 -273
-60 0 -60 0 0 268 c0 148 3 272 7 276 4 4 30 6 58 4 l50 -3 3 -272z m-418 -73
l0 -200 -60 0 -60 0 0 193 c0 107 3 197 7 200 3 4 30 7 60 7 l53 0 0 -200z
m194 74 c14 -5 16 -27 16 -140 l0 -134 -60 0 -60 0 0 133 c0 74 3 137 7 140 8
9 76 9 97 1z m-406 -211 l3 -63 -61 0 -60 0 0 58 c0 32 3 62 7 66 4 4 30 6 58
4 l50 -3 3 -62z"/>
<path d="M3550 7024 c-117 -69 -290 -183 -290 -191 0 -3 17 -27 38 -52 131
-160 318 -279 521 -332 101 -27 284 -36 392 -19 161 25 351 103 448 185 95 80
136 118 171 163 l40 50 -37 30 c-55 42 -201 135 -286 181 l-72 39 -64 -58
c-105 -95 -216 -140 -352 -140 -140 0 -265 55 -369 163 -20 20 -38 37 -41 37
-2 0 -47 -25 -99 -56z"/>
<path d="M1254 5756 c-3 -7 -4 -33 -2 -57 4 -44 11 -39 22 13 5 20 14 29 34
34 15 3 35 8 45 11 16 4 17 -9 17 -166 0 -168 0 -170 -22 -174 -13 -2 20 -4
72 -4 52 0 85 2 73 4 -23 4 -23 6 -23 173 l0 169 38 -6 c45 -7 58 -18 71 -63
9 -33 10 -32 8 20 l-2 55 -163 3 c-132 2 -163 0 -168 -12z"/>
<path d="M1648 5763 c9 -3 12 -48 12 -173 0 -152 -2 -169 -17 -173 -10 -3 51
-5 135 -6 l152 -1 -1 63 c-1 58 -1 60 -10 27 -15 -54 -51 -80 -110 -80 l-49 0
0 85 c0 85 0 85 26 85 33 0 64 -17 64 -35 0 -8 5 -15 10 -15 6 0 10 25 10 55
0 58 -9 70 -25 33 -7 -17 -20 -24 -47 -26 l-38 -3 0 80 c0 91 0 91 85 71 44
-10 65 -32 65 -68 0 -13 3 -21 8 -19 4 3 5 28 4 56 l-4 51 -141 -1 c-78 0
-136 -3 -129 -6z"/>
<path d="M2054 5751 c-36 -22 -59 -78 -49 -113 12 -38 37 -60 110 -93 79 -37
98 -54 93 -87 -5 -34 -45 -50 -94 -37 -43 12 -104 70 -104 99 0 10 -4 22 -10
25 -6 4 -10 -20 -10 -64 0 -68 1 -71 20 -59 15 10 29 9 70 -6 60 -22 126 -18
163 10 44 33 52 120 15 158 -8 8 -50 31 -93 53 -90 44 -104 60 -87 97 27 59
158 11 163 -59 1 -12 4 -8 9 12 8 36 1 91 -9 74 -5 -7 -18 -8 -41 -1 -54 15
-112 12 -146 -9z"/>
<path d="M2321 5720 c0 -27 4 -52 9 -55 6 -4 10 6 10 23 0 36 25 59 70 64 l35
3 0 -165 0 -164 -25 -7 c-14 -4 16 -7 70 -7 67 -1 88 2 72 8 -22 9 -22 11 -20
172 l3 163 35 -3 c43 -4 60 -19 70 -63 7 -30 8 -28 9 24 l1 57 -169 0 -168 0
-2 -50z"/>
<path d="M2723 5756 c11 -12 15 -50 16 -170 2 -139 0 -155 -16 -165 -14 -8 2
-10 62 -10 44 1 72 3 63 6 -16 4 -18 21 -18 168 0 146 2 165 18 174 12 8 -3
10 -61 11 -68 0 -77 -2 -64 -14z"/>
<path d="M2928 5760 c22 -9 22 -13 22 -174 0 -163 0 -165 -22 -169 -13 -2 2
-4 32 -4 30 0 43 2 28 4 l-28 4 0 156 c0 86 2 154 4 152 5 -6 113 -261 120
-286 4 -11 13 -26 20 -32 11 -9 25 20 72 143 32 85 61 153 65 150 4 -2 8 -64
8 -138 2 -120 0 -136 -16 -145 -14 -8 2 -10 62 -10 59 0 75 2 63 10 -16 9 -18
28 -18 169 0 141 2 160 18 170 13 7 1 9 -47 8 l-65 -3 -30 -75 c-53 -135 -60
-150 -65 -150 -7 1 -24 37 -72 153 l-32 77 -71 -1 c-53 0 -65 -3 -48 -9z"/>
<path d="M3487 5745 c-50 -35 -77 -90 -77 -161 0 -100 42 -153 136 -174 145
-33 256 80 224 225 -20 89 -84 135 -187 135 -46 0 -66 -5 -96 -25z m155 -15
c39 -53 41 -228 4 -285 -19 -29 -75 -35 -100 -10 -51 51 -47 248 6 312 17 21
68 12 90 -17z"/>
<path d="M3810 5762 c0 -6 7 -12 15 -16 12 -4 15 -32 15 -165 0 -144 -2 -160
-17 -164 -10 -3 5 -5 32 -5 36 0 43 2 25 7 -25 7 -25 7 -28 144 -1 75 1 137 5
137 5 0 62 -67 128 -149 65 -83 122 -148 125 -146 10 6 9 335 -1 335 -4 0 -10
-40 -11 -89 l-3 -90 -85 102 -86 102 -57 3 c-31 2 -57 -1 -57 -6z"/>
<path d="M4064 5761 c6 -9 85 -6 94 4 3 3 -18 5 -47 5 -29 0 -50 -4 -47 -9z"/>
<path d="M4201 5760 c9 -5 42 -54 73 -108 52 -92 56 -102 56 -164 0 -44 -4
-68 -12 -71 -7 -3 21 -6 62 -6 56 0 70 2 58 10 -14 8 -18 24 -18 74 0 56 6 76
46 159 35 72 53 98 73 105 22 8 18 9 -29 10 -43 0 -50 -2 -32 -9 l22 -9 -37
-76 c-21 -41 -40 -75 -43 -75 -12 0 -73 117 -74 142 l-1 28 -80 0 c-56 -1 -75
-4 -64 -10z"/>
<path d="M4613 5760 c15 -10 17 -30 17 -175 0 -147 -2 -164 -17 -168 -10 -3
30 -5 88 -6 113 -1 160 9 198 43 82 73 62 225 -36 283 -41 24 -58 27 -157 30
-83 3 -107 1 -93 -7z m183 -27 c51 -47 70 -120 54 -205 -12 -63 -42 -96 -93
-105 l-37 -6 0 167 0 166 28 0 c16 0 38 -8 48 -17z"/>
<path d="M5145 5733 c-119 -292 -129 -312 -155 -316 -14 -2 2 -4 35 -4 33 0
50 2 38 4 -26 5 -28 14 -11 60 12 33 12 33 78 33 l65 0 22 -40 c20 -37 20 -41
5 -50 -12 -6 8 -9 68 -9 49 0 77 4 66 8 -13 5 -46 66 -97 176 -42 93 -82 171
-88 173 -5 2 -17 -14 -26 -35z m15 -143 c16 -33 30 -62 30 -65 0 -3 -25 -5
-55 -5 -30 0 -55 4 -55 10 0 10 43 120 47 120 2 0 16 -27 33 -60z"/>
<path d="M5397 5764 c-11 -11 -8 -94 3 -94 6 0 10 13 10 30 0 32 14 43 68 53
l32 7 0 -170 c0 -167 0 -169 -22 -173 -13 -2 20 -4 72 -4 52 0 85 2 72 4 -22
4 -22 5 -20 171 l3 167 35 -3 c43 -4 51 -12 67 -60 l12 -37 -2 55 -2 55 -161
3 c-88 1 -163 -1 -167 -4z"/>
<path d="M5921 5728 c-10 -24 -42 -101 -72 -172 -36 -86 -61 -131 -74 -137
-14 -5 -5 -8 30 -7 28 0 40 3 27 5 -17 3 -22 9 -17 21 4 9 12 29 17 45 10 26
13 27 78 27 67 0 68 0 84 -34 20 -43 20 -53 -1 -59 -10 -3 23 -5 72 -5 50 0
82 2 72 5 -12 3 -37 43 -68 111 -104 222 -114 242 -122 242 -4 0 -16 -19 -26
-42z m17 -137 c12 -27 22 -54 22 -60 0 -11 -92 -16 -102 -5 -3 3 0 17 7 32 8
15 18 39 24 55 6 15 14 27 18 27 5 0 18 -22 31 -49z"/>
<path d="M6193 5759 c15 -9 17 -28 17 -169 0 -141 -2 -160 -17 -169 -13 -8 3
-10 62 -10 59 0 75 2 63 10 -15 8 -18 24 -18 89 l0 80 65 0 65 0 0 -80 c0 -65
-3 -81 -17 -89 -13 -8 5 -10 67 -10 61 0 79 3 63 9 -23 9 -23 13 -23 170 0
157 0 161 23 170 16 6 -2 9 -63 9 -62 0 -80 -2 -67 -10 13 -8 17 -24 17 -78 0
-37 -2 -70 -4 -72 -2 -2 -32 -2 -65 -1 l-61 4 0 68 c0 55 4 71 18 79 12 8 -4
10 -63 10 -59 0 -75 -2 -62 -10z"/>
<path d="M6681 5749 c-48 -29 -68 -57 -81 -112 -32 -130 44 -231 175 -231 71
0 125 26 159 77 25 38 27 48 23 114 -5 83 -31 128 -90 157 -51 23 -143 21
-186 -5z m138 -8 c31 -31 44 -97 38 -185 -6 -96 -26 -129 -80 -134 -46 -4 -64
22 -79 110 -25 149 51 279 121 209z"/>
<path d="M7103 5763 c-11 -2 -37 -20 -56 -38 -64 -62 -73 -187 -19 -260 41
-56 160 -77 239 -41 24 10 50 34 67 59 25 38 27 49 23 113 -4 81 -33 130 -92
158 -31 15 -109 19 -162 9z m117 -25 c29 -31 35 -60 35 -165 0 -78 -4 -96 -21
-120 -17 -22 -29 -28 -60 -28 -36 0 -41 4 -59 40 -10 22 -21 65 -23 96 -5 64
20 167 46 187 24 19 59 14 82 -10z"/>
<path d="M7418 5764 c9 -4 12 -49 12 -174 0 -152 -2 -169 -17 -173 -10 -3 34
-5 97 -6 102 -1 121 2 160 22 86 44 113 166 55 248 -46 65 -91 83 -215 86 -58
2 -99 0 -92 -3z m187 -36 c38 -29 55 -76 55 -149 0 -78 -22 -127 -66 -145 -66
-27 -64 -31 -64 151 l0 165 24 0 c13 0 36 -10 51 -22z"/>
<path d="M1694 5107 c-2 -7 -3 -64 -2 -127 2 -80 7 -115 16 -118 9 -3 12 13
12 56 0 66 15 92 54 92 36 0 46 -20 46 -87 0 -35 4 -63 10 -63 17 0 12 145 -5
160 -17 14 -51 17 -82 6 -22 -7 -23 -5 -23 43 0 48 -14 68 -26 38z"/>
<path d="M2781 4985 c-56 -146 -56 -148 -40 -143 10 3 109 249 109 269 0 5 -4
9 -9 9 -5 0 -32 -61 -60 -135z"/>
<path d="M2925 5084 c-8 -19 -26 -66 -39 -102 -13 -37 -32 -84 -40 -104 -14
-33 -14 -38 -2 -38 8 0 18 10 22 23 3 12 26 73 50 134 48 124 48 123 34 123
-5 0 -16 -16 -25 -36z"/>
<path d="M4957 5113 c-4 -3 -7 -26 -7 -51 0 -38 -2 -43 -17 -36 -73 32 -135
-28 -112 -109 14 -50 63 -71 109 -47 13 7 21 7 25 0 17 -28 25 12 25 126 0
113 -5 136 -23 117z m-23 -119 c21 -20 20 -73 -1 -96 -48 -53 -119 26 -83 93
13 23 62 26 84 3z"/>
<path d="M5620 4990 c0 -101 3 -131 13 -128 7 3 13 28 15 63 4 71 15 87 56 83
31 -3 31 -3 36 -72 3 -43 10 -71 18 -74 9 -3 12 14 12 65 0 37 -4 73 -8 79
-15 23 -49 33 -80 25 l-31 -9 -3 46 c-2 25 -9 47 -15 50 -10 3 -13 -27 -13
-128z"/>
<path d="M6418 5073 c-2 -37 -6 -47 -18 -44 -38 12 -75 3 -97 -23 -28 -33 -29
-71 -3 -113 16 -27 26 -33 53 -33 18 0 38 5 45 12 8 8 15 8 25 0 8 -7 17 -9
20 -5 9 8 9 216 1 237 -11 30 -23 16 -26 -31z m-10 -88 c17 -37 15 -62 -8 -85
-54 -54 -118 19 -80 91 17 30 74 26 88 -6z"/>
<path d="M3734 5099 c-10 -17 13 -36 27 -22 12 12 4 33 -11 33 -5 0 -12 -5
-16 -11z"/>
<path d="M1930 5056 c0 -18 -7 -26 -22 -29 l-23 -4 22 -8 c19 -6 23 -15 25
-73 3 -72 17 -90 61 -78 35 8 35 25 0 23 -27 -2 -28 -1 -31 59 l-3 62 35 4
c62 7 70 -1 76 -78 l5 -69 34 0 c21 0 36 6 39 14 3 10 0 12 -12 8 -34 -13 -46
3 -46 64 0 59 0 59 29 59 42 0 48 18 9 22 -25 2 -34 8 -36 26 -2 12 -7 22 -12
22 -5 0 -10 -10 -12 -22 -3 -20 -10 -23 -55 -26 -51 -3 -53 -2 -53 22 0 16 -6
26 -15 26 -9 0 -15 -9 -15 -24z"/>
<path d="M3030 5056 c0 -18 -7 -26 -22 -29 l-23 -4 23 -8 c20 -7 22 -14 22
-70 0 -42 5 -66 14 -74 19 -16 68 -10 74 8 3 9 -2 11 -17 6 -35 -11 -51 11
-51 71 l0 54 35 0 c44 0 46 18 3 22 -25 2 -34 8 -36 26 -4 30 -22 28 -22 -2z"/>
<path d="M3597 5073 c-4 -3 -7 -15 -7 -25 0 -10 -7 -18 -15 -18 -8 0 -15 -4
-15 -10 0 -5 6 -10 14 -10 10 0 15 -18 18 -66 3 -42 9 -69 19 -75 20 -13 62
-7 67 10 3 10 0 12 -12 8 -34 -13 -46 3 -46 64 0 59 0 59 30 59 17 0 30 5 30
10 0 6 -13 10 -30 10 -25 0 -30 4 -30 25 0 24 -10 32 -23 18z"/>
<path d="M5270 5057 c0 -19 -6 -27 -22 -30 l-23 -4 23 -8 c19 -6 22 -14 22
-65 0 -32 3 -65 6 -74 6 -16 39 -21 68 -10 26 10 18 25 -9 18 -21 -5 -26 -1
-35 22 -17 44 -9 98 15 102 43 7 50 15 15 21 -26 4 -35 11 -35 25 0 11 -6 21
-13 24 -7 2 -12 -6 -12 -21z"/>
<path d="M2210 4916 c0 -105 2 -126 15 -126 11 0 15 12 15 47 0 37 3 44 12 35
20 -20 67 -14 93 13 27 26 34 80 15 114 -15 29 -61 44 -94 31 -21 -8 -32 -7
-42 1 -12 10 -14 -9 -14 -115z m126 69 c37 -56 -34 -136 -80 -89 -19 18 -21
85 -4 102 21 21 66 14 84 -13z"/>
<path d="M2464 5030 c-29 -12 -37 -26 -30 -55 5 -18 18 -27 54 -37 36 -10 47
-18 47 -33 0 -26 -38 -32 -70 -11 -30 19 -48 0 -20 -21 29 -20 89 -17 110 7
28 31 8 64 -49 79 -35 9 -46 16 -46 31 0 16 7 20 34 20 19 0 38 -4 41 -10 9
-14 25 -12 25 3 0 14 -41 38 -63 36 -7 0 -22 -4 -33 -9z"/>
<path d="M2635 5031 c-8 -15 3 -31 21 -31 9 0 14 7 12 17 -4 20 -24 28 -33 14z"/>
<path d="M3220 5033 c-66 -24 -78 -117 -21 -160 22 -17 73 -16 97 1 26 19 10
33 -20 16 -29 -15 -71 -7 -80 16 -10 27 3 34 65 34 51 0 59 2 59 19 0 33 -11
51 -41 66 -30 16 -35 17 -59 8z m64 -39 c11 -30 6 -34 -44 -34 -54 0 -55 1
-40 31 13 24 75 27 84 3z"/>
<path d="M3410 5027 c-28 -14 -38 -50 -20 -72 6 -7 29 -16 51 -20 45 -8 56
-20 39 -40 -16 -19 -50 -19 -76 0 -27 18 -39 7 -17 -17 24 -27 97 -25 117 3
23 33 6 62 -42 74 -50 13 -71 30 -55 46 6 6 24 12 39 12 16 0 30 -6 32 -12 4
-13 32 -15 32 -2 0 28 -65 46 -100 28z"/>
<path d="M3892 5030 c-12 -5 -29 -7 -37 -4 -13 5 -15 -8 -15 -81 0 -65 3 -86
13 -83 7 3 13 28 15 63 4 69 14 85 53 85 32 0 37 -11 41 -95 4 -68 22 -59 26
12 4 67 14 83 52 83 34 0 40 -14 40 -92 0 -43 3 -59 13 -56 7 3 13 28 15 66 3
55 0 65 -22 87 -20 20 -32 24 -53 19 -16 -4 -33 -11 -40 -16 -8 -7 -20 -4 -35
6 -25 18 -33 19 -66 6z"/>
<path d="M4225 5031 c-42 -18 -62 -71 -45 -117 14 -36 38 -53 75 -53 100 0
114 152 15 173 -14 3 -34 1 -45 -3z m73 -33 c19 -19 15 -82 -8 -103 -21 -19
-68 -14 -82 8 -11 17 -10 65 2 88 12 22 68 27 88 7z"/>
<path d="M4465 5030 c-11 -4 -29 -6 -40 -3 -20 4 -21 0 -19 -79 3 -92 24 -125
24 -36 0 66 20 98 59 98 35 0 41 -13 41 -91 0 -63 14 -79 25 -28 11 49 -3 117
-28 134 -24 17 -33 17 -62 5z"/>
<path d="M4732 4999 c-10 -24 -22 -56 -26 -71 -3 -16 -10 -28 -14 -28 -4 0
-18 29 -30 65 -14 38 -30 65 -38 65 -8 0 -14 -1 -14 -3 0 -1 16 -40 35 -86 34
-80 35 -85 19 -108 -10 -17 -22 -23 -35 -20 -10 3 -22 1 -25 -4 -8 -13 22 -22
49 -15 19 4 33 28 70 118 31 72 44 115 37 122 -6 6 -16 -6 -28 -35z"/>
<path d="M5084 5030 c-23 -9 -39 -28 -31 -36 2 -2 17 2 33 11 24 12 32 12 49
1 31 -21 24 -40 -17 -47 -39 -6 -78 -33 -78 -54 0 -7 9 -20 20 -30 18 -16 27
-17 60 -8 26 6 42 7 49 0 5 -5 18 -7 28 -3 17 7 17 8 1 17 -14 8 -18 23 -18
68 0 35 -5 63 -13 69 -19 16 -60 22 -83 12z m76 -98 c0 -4 -7 -18 -16 -30 -11
-16 -24 -22 -42 -20 -36 4 -37 39 -2 49 35 10 60 10 60 1z"/>
<path d="M5433 5028 c-13 -6 -23 -17 -23 -25 0 -16 12 -17 27 -2 6 6 23 9 39
7 21 -2 30 -9 32 -25 3 -20 -1 -23 -27 -23 -42 0 -86 -39 -77 -68 9 -27 50
-39 88 -24 16 6 28 6 28 1 0 -12 37 -12 45 1 3 6 -1 10 -9 10 -13 0 -16 13
-16 64 0 41 -5 67 -13 75 -21 17 -69 22 -94 9z m77 -107 c0 -25 -24 -43 -52
-39 -31 4 -36 36 -6 47 38 16 58 13 58 -8z"/>
<path d="M5895 5033 c-39 -10 -55 -34 -55 -84 0 -59 26 -89 77 -89 49 0 83 37
83 90 0 38 -26 80 -49 80 -5 0 -15 2 -23 4 -7 2 -22 1 -33 -1z m64 -37 c7 -8
11 -34 9 -58 -3 -36 -7 -44 -31 -52 -36 -13 -59 1 -71 42 -12 43 13 82 53 82
16 0 34 -6 40 -14z"/>
<path d="M6104 5026 c-35 -15 -44 -32 -44 -80 0 -50 33 -86 79 -86 53 0 81 32
81 92 0 69 -52 102 -116 74z m86 -46 c15 -37 6 -76 -21 -91 -32 -17 -66 -3
-78 31 -18 52 5 90 54 90 27 0 35 -5 45 -30z"/>
<path d="M6665 5033 c-31 -7 -55 -44 -55 -86 0 -73 76 -112 131 -68 13 11 21
23 17 27 -5 4 -16 0 -26 -9 -41 -37 -96 -10 -96 46 0 19 6 42 13 51 17 20 65
21 81 1 7 -8 16 -12 21 -10 14 9 0 32 -27 44 -26 12 -27 12 -59 4z"/>
<path d="M6863 5030 c-29 -12 -43 -40 -43 -87 0 -44 6 -55 37 -72 35 -18 72
-13 98 14 44 43 27 125 -28 145 -35 12 -35 12 -64 0z m84 -51 c18 -37 0 -87
-35 -96 -51 -12 -85 40 -62 97 11 28 17 31 49 28 27 -2 39 -9 48 -29z"/>
<path d="M7102 5030 c-12 -5 -29 -7 -37 -4 -13 5 -15 -8 -15 -80 0 -51 4 -86
10 -86 6 0 10 24 10 53 0 60 20 97 54 97 36 0 46 -19 46 -87 0 -35 4 -63 10
-63 6 0 10 21 10 48 0 63 18 96 54 100 38 5 46 -10 46 -85 0 -37 4 -63 10 -63
7 0 10 29 8 77 -3 74 -4 78 -31 91 -23 11 -32 11 -57 -2 -26 -13 -34 -13 -58
0 -31 16 -29 16 -60 4z"/>
<path d="M3740 4945 c0 -50 4 -85 10 -85 6 0 10 35 10 85 0 50 -4 85 -10 85
-6 0 -10 -35 -10 -85z"/>
<path d="M2634 4889 c-10 -17 13 -36 27 -22 12 12 4 33 -11 33 -5 0 -12 -5
-16 -11z"/>
<path d="M6520 4880 c0 -11 7 -20 15 -20 8 0 15 9 15 20 0 11 -7 20 -15 20 -8
0 -15 -9 -15 -20z"/>
<path d="M2670 4152 c0 -11 11 -54 24 -98 14 -43 33 -108 44 -144 17 -55 23
-65 44 -68 22 -3 27 2 38 45 41 157 53 200 57 213 5 15 72 -209 73 -242 0 -13
8 -18 29 -18 28 0 29 2 72 153 49 169 49 169 32 174 -17 6 -26 -19 -64 -165
-18 -68 -35 -120 -39 -115 -4 4 -22 68 -41 140 -32 127 -48 158 -71 135 -8 -8
-77 -254 -78 -276 0 -5 -3 -6 -7 -2 -7 7 -12 23 -53 181 -21 83 -32 104 -52
105 -5 0 -8 -8 -8 -18z"/>
<path d="M3186 4163 c-3 -3 -6 -78 -6 -165 0 -132 2 -158 15 -158 11 0 15 17
17 73 l3 72 88 3 87 3 0 -76 c0 -68 2 -75 20 -75 19 0 20 7 20 158 0 87 -3
161 -6 165 -3 3 -12 3 -20 0 -10 -4 -14 -24 -14 -74 l0 -69 -90 0 -90 0 0 69
c0 65 -8 90 -24 74z"/>
<path d="M3633 4162 c-7 -4 -24 -39 -38 -77 -14 -39 -33 -85 -41 -103 -9 -18
-24 -58 -34 -88 -18 -50 -18 -54 -2 -54 11 0 25 17 37 43 l20 42 75 0 75 0 15
-42 c10 -29 20 -43 32 -43 10 0 18 3 18 8 1 16 -115 307 -125 314 -13 10 -17
10 -32 0z m45 -115 c12 -33 22 -63 22 -68 0 -5 -25 -9 -56 -9 -49 0 -55 2 -50
18 35 95 52 130 57 124 3 -4 15 -33 27 -65z"/>
<path d="M3790 4160 c-24 -15 0 -28 55 -32 l50 -3 3 -142 c2 -118 5 -143 17
-143 12 0 15 23 16 130 1 72 2 136 3 143 1 8 22 13 59 15 39 2 57 7 57 16 0 8
-7 17 -16 20 -25 10 -228 7 -244 -4z"/>
<path d="M4163 4163 c-29 -6 -73 -59 -73 -87 0 -41 36 -74 95 -87 64 -14 115
-42 115 -64 0 -8 -10 -25 -21 -36 -31 -31 -109 -23 -148 15 -25 24 -29 25 -41
11 -34 -41 88 -95 167 -73 20 5 46 21 59 35 52 56 16 114 -86 139 -69 17 -100
37 -100 64 0 54 104 69 152 23 21 -20 31 -24 39 -16 40 40 -75 95 -158 76z"/>
<path d="M4514 4162 c-10 -6 -51 -104 -110 -259 -22 -59 -23 -63 -6 -63 12 0
25 15 38 43 l19 42 73 3 72 3 20 -46 c11 -25 26 -45 35 -45 8 0 15 3 15 8 -1
7 -28 84 -49 137 -6 17 -24 62 -39 100 -24 63 -37 85 -52 85 -3 0 -10 -4 -16
-8z m44 -115 c12 -33 22 -64 22 -70 0 -8 -20 -12 -56 -12 -44 0 -54 3 -50 15
24 78 50 139 56 133 4 -4 16 -34 28 -66z"/>
<path d="M4757 4163 c-4 -3 -7 -78 -7 -165 0 -131 2 -158 15 -158 10 0 15 16
17 63 l3 62 59 3 c74 4 113 28 122 78 8 42 -6 77 -39 99 -25 16 -158 30 -170
18z m158 -59 c20 -27 18 -61 -5 -84 -26 -26 -114 -29 -124 -4 -9 22 -7 99 2
108 15 16 112 0 127 -20z"/>
<path d="M5073 4162 c-23 -3 -23 -5 -23 -163 0 -152 1 -159 20 -159 18 0 20 7
20 65 l0 65 61 0 c54 0 64 3 90 29 21 22 29 39 29 66 0 45 -44 94 -87 96 -15
1 -41 3 -58 4 -16 1 -40 0 -52 -3z m135 -48 c26 -18 31 -74 8 -93 -8 -7 -40
-15 -70 -18 l-56 -6 0 67 0 66 48 0 c27 0 57 -7 70 -16z"/>
<path d="M2710 3543 c-59 -53 -64 -197 -10 -266 49 -62 160 -41 190 37 14 39
12 151 -4 189 -29 70 -120 90 -176 40z m105 -9 c28 -11 45 -60 45 -129 0 -75
-20 -120 -56 -129 -73 -19 -119 69 -95 178 16 72 53 100 106 80z"/>
<path d="M3015 3545 c-29 -28 -32 -65 -9 -104 16 -26 15 -29 -11 -57 -21 -22
-25 -35 -21 -58 3 -15 6 -33 6 -37 0 -18 66 -49 103 -49 55 0 105 24 117 56
14 35 4 82 -21 97 -23 14 -25 35 -4 43 15 6 21 58 9 89 -10 24 -58 45 -104 45
-30 0 -47 -6 -65 -25z m118 -19 c10 -8 17 -26 17 -45 0 -25 -6 -34 -27 -42
-30 -11 -74 -4 -85 14 -34 53 43 112 95 73z m20 -158 c21 -25 21 -37 1 -66
-12 -18 -25 -22 -64 -22 -42 0 -52 4 -66 25 -45 69 73 126 129 63z"/>
<path d="M3318 3538 c-51 -33 -66 -65 -25 -53 12 4 26 11 32 16 5 5 15 9 22 9
10 0 13 -31 13 -135 0 -113 2 -135 15 -135 13 0 15 25 15 165 0 126 -3 165
-12 164 -7 0 -34 -14 -60 -31z"/>
<path d="M3607 3553 c-30 -35 -137 -197 -137 -209 0 -9 23 -14 78 -16 l77 -3
3 -42 c2 -29 8 -43 17 -43 16 0 28 36 23 66 -2 14 4 20 25 22 35 4 37 32 2 32
-19 0 -25 5 -26 23 -1 35 -6 140 -8 165 -1 27 -31 30 -54 5z m23 -113 l0 -80
-55 0 c-31 0 -55 5 -55 10 0 6 20 38 45 72 25 34 45 66 45 70 0 4 5 8 10 8 6
0 10 -33 10 -80z"/>
<path d="M3835 3558 c-33 -19 -44 -41 -45 -86 0 -55 23 -89 70 -103 34 -10 44
-9 81 9 24 12 45 20 47 18 7 -8 -17 -79 -34 -97 -12 -13 -31 -19 -60 -19 -35
0 -47 5 -60 24 -9 15 -21 22 -30 19 -21 -8 -17 -27 12 -54 21 -19 36 -24 80
-24 69 0 99 27 116 105 26 125 -24 220 -113 220 -24 0 -53 -6 -64 -12z m97
-30 c36 -17 45 -64 20 -98 -23 -31 -65 -37 -98 -15 -21 14 -25 23 -22 58 2 33
8 44 28 54 31 16 40 16 72 1z"/>
<path d="M4070 3550 c0 -19 7 -20 96 -20 l96 0 -16 -22 c-60 -84 -111 -237
-86 -262 17 -17 31 8 40 71 9 66 37 128 83 185 48 60 37 68 -98 68 -108 0
-115 -1 -115 -20z"/>
<path d="M4408 3553 c-32 -20 -44 -43 -26 -54 7 -5 22 2 36 17 45 44 112 21
112 -38 0 -30 -15 -45 -50 -50 -33 -4 -40 -38 -8 -38 34 0 68 -27 68 -54 0
-59 -96 -81 -136 -30 -21 26 -44 26 -44 0 0 -22 46 -57 83 -63 47 -7 78 1 110
31 35 32 35 74 2 110 l-25 27 20 21 c29 32 26 83 -6 113 -32 30 -95 33 -136 8z"/>
<path d="M4702 3560 c-34 -14 -58 -72 -46 -116 18 -74 115 -105 168 -55 l26
24 0 -32 c0 -38 -23 -86 -45 -95 -41 -15 -105 -3 -105 20 0 8 -9 14 -20 14
-11 0 -20 -4 -20 -9 0 -58 93 -90 160 -56 45 23 64 69 64 150 0 81 -19 127
-64 150 -32 17 -83 19 -118 5z m91 -31 c12 -7 25 -17 29 -23 12 -18 9 -73 -4
-84 -39 -30 -111 -19 -123 19 -12 35 5 77 33 88 36 13 40 13 65 0z"/>
<path d="M4992 3545 c-35 -29 -45 -60 -26 -76 11 -9 16 -6 23 14 12 30 48 57
76 57 12 0 31 -9 43 -20 53 -50 6 -141 -114 -222 -60 -40 -43 -53 79 -56 102
-3 107 -2 107 18 0 18 -6 20 -80 20 l-81 0 53 43 c70 57 98 100 98 149 0 87
-108 132 -178 73z"/>
<path d="M5286 3549 c-14 -11 -26 -26 -26 -34 0 -23 24 -23 47 2 27 28 66 30
93 3 35 -35 23 -76 -26 -89 -37 -10 -46 -41 -12 -41 53 0 81 -61 45 -97 -25
-25 -88 -19 -114 11 -26 30 -43 33 -43 8 0 -24 38 -59 73 -67 39 -10 94 3 117
27 30 32 27 91 -5 116 -28 22 -32 32 -11 32 23 0 40 66 26 99 -21 53 -114 69
-164 30z"/>
<path d="M5576 3549 c-14 -11 -26 -27 -26 -34 0 -26 19 -26 44 0 14 14 37 25
52 25 36 0 67 -36 59 -70 -8 -29 -21 -40 -52 -40 -16 0 -23 -6 -23 -20 0 -15
7 -20 24 -20 33 0 56 -25 56 -60 0 -55 -92 -72 -130 -23 -23 30 -47 20 -35
-16 24 -76 195 -58 207 22 5 29 -24 87 -43 87 -6 0 1 14 15 31 34 40 33 74 -3
110 -37 37 -104 41 -145 8z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
